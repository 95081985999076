#react-burger-menu-btn,
#react-burger-cross-btn {
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

#react-burger-menu-btn {
  width: 70px !important;
}

.bm-menu-wrap {
  margin-top: -50px;
  transition-duration: 300ms !important;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.8) !important;
}

.bm-cross-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconSidebarMenu {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
